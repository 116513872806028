import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// eslint-disable-next-line
import PrivateRoute from "../common/PrivateRoute";
import { restoreSession } from "../api/auth";

import { ThemeProvider } from "evergreen-ui";

import Header from "../common/header/HeaderComponent";
import UpdateChecker from "../common/update-checker/UpdateChecker";

import styles from "./global.module.css";
import GisualEvergreenTheme from "./GisualEvergreenTheme";

import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

const ForgotPasswordComponent = React.lazy(() => import("./components/login/ForgotPasswordComponent"));
const ResetPasswordComponent = React.lazy(() => import("./components/login/ResetPasswordComponent"));
const LoginComponent = React.lazy(() => import("./components/login/LoginComponent"));
const LogoutComponent = React.lazy(() => import("./components/login/LogoutComponent"));
const ClaimTaskComponent = React.lazy(() => import("./components/tasks/pages/ClaimTaskComponent"));
const TaskPage = React.lazy(() => import("./components/tasks/pages/TaskPage"));

function AppContainer(props) {
  restoreSession();
  // const [isAuth, setIsAuth] = useState(false); //then delete

  return (
    <Router>
      <div className={styles.AppContainer}>
        <ThemeProvider value={GisualEvergreenTheme}>
          {/* <Header isAuth={isAuth} /> */}
          <Header />
          <UpdateChecker />

          <Suspense fallback={null}>
            <Switch>
              <PrivateRoute path="/tasks/:task_id" component={TaskPage} />
              {/* <Route path="/" exact component={ClaimTaskComponent} /> */}
              {/* <Route path="/tasks/:task_id" component={TaskPage} /> */}

              {/* <Route path="/login" render={() => <LoginComponent setIsAuth={setIsAuth} />} /> */}
              <Route path="/login" component={LoginComponent} />
              <Route path="/logout" component={LogoutComponent} />
              <Route path="/forgot-password" component={ForgotPasswordComponent} />
              <Route path="/reset-forgotten-password/:token?" component={ResetPasswordComponent} />
              {/*<Route path="/signups/:signup_id?" component={SignUpComponent} />*/}

              <PrivateRoute path="/" exact component={ClaimTaskComponent} />
            </Switch>
          </Suspense>
        </ThemeProvider>
      </div>
    </Router>
  );
}

export default AppContainer;
