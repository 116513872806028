import jwt_decode from 'jwt-decode';
import mixpanel from 'mixpanel-browser'
import { post, get } from './client.js';
import { identifyUser } from './analytics.js'

import store from '../app/store'

import {
  saveItemsToLocalStorage,
  fetchItemsFromLocalStorage,
  fetchItemFromLocalStorage,
  removeItemsFromLocalStorage,
} from './../helpers/localStore'
import { setAllAuthState } from '../app/store/AuthSlice.js';
import { getUser, setOwnUser } from './aaa.js';

const authLSPrefix = 'session'

let user = getEmptyUserObject()

function getEmptyUserObject() {
  return {
    uid: null,
    orgid: null,
    email: null,
    isAdmin: null,
    entitlements: {},
  }
}

export async function refreshAccessToken() {
  try {
    await post(
      '/refresh',
      {
        // Refresh token is sent in cookies
        data: {},
        shouldProcessError: false,
      },
    )

    // Refreshed access token is received in cookies

    return true
  } catch {
    window.location.replace('/logout')

    return false
  }
}

export function _sendForgotPasswordEmail(email) {
  const body = {
    email_address: email
  }

  return post(
    '/send-forgot-password-email',
    {
      data: body,
      shouldProcessError: false,
      withCredentials: false,
    },
  )
}

export async function resetPassword(body) {
  const response = await post(
    '/reset-password',
    {
      data: body,
      shouldProcessError: false,
    },
  )

  if (await refreshAccessToken()) {
    await createSession(response.data.user_id)
  }
}

function identifyUserInMixpanel() {
  identifyUser(user.uid, {
    '$email': user.email,
    'organization_id': user.orgid,
    'latest_login': Date.now(),
  })
}

export function userIsAdmin() {
  if (!validateSession()) {
    return false
  }

  return fetchItemFromLocalStorage('isAdmin', authLSPrefix) === true
}

export function isTokenValid(token) {
  if (!token) {
    return false;
  }

  const decodedToken = jwt_decode(token);
  return decodedToken && decodedToken.exp*1000 > Date.now();
}

function isUserAdministrator(userResponse) {
  const user_groups = userResponse.data.groups.filter(
    (group) => userResponse.data.organization_id === group.organization_id
  );

  return user_groups.some(group => group.group_name === 'administrators')
}

async function createSession(userId) {

  const userResponse = await getUser(userId)
  user.uid = userResponse.data.user_id
  user.email = userResponse.data.email_address
  user.orgid = userResponse.data.organization_id
  user.isAdmin = isUserAdministrator(userResponse)

  setOwnUser(userResponse.data)
  saveItemsToLocalStorage(user, authLSPrefix)

  identifyUserInMixpanel()

  store.dispatch(setAllAuthState({
    isAuthenticated: true,
  }))

  return true
}

export async function verifyUserEmail(
  emailAddress,
  reCaptchaTokenV2,
  reCaptchaTokenV3,
) {
  const body = {
    email_address: emailAddress,
    recaptcha_token_v2: reCaptchaTokenV2,
    recaptcha_token_v3: reCaptchaTokenV3,
  }

  const response = await post(
    '/authenticate/email',
    {
      data: body,
      shouldProcessError: false,
      withCredentials: false,
    },
  )

  if (response.data.redirect_url) {
    window.location.replace(response.data.redirect_url);
    return true
  }

  return false
}

export async function verifyUserPassword(email_address, password) {
  const body = {
    email_address: email_address,
    password: password
  }

  const response = await post(
    '/authenticate/user',
    {
      data: body,
      shouldProcessError: false,
    },
  )

  return await createSession(response.data.user_id)
}

export function restoreSession() {
  user = fetchItemsFromLocalStorage(Object.keys(user), authLSPrefix)

  const sessionIsValid = validateSession()

  store.dispatch(setAllAuthState({
    isAuthenticated: sessionIsValid,
  }))
}

export function validateSession() {
  return user.uid ? true : false
}

export async function performLogout() {
  if (!validateSession()) {
    return
  }

  try {
    const response = await get(
      '/logout',
      {
        shouldProcessError: false,
      },
    )

    if (response.data.redirect_url) {
      window.location.replace(response.data.redirect_url)
      return true
    }
  } catch {}

  return false
}

export function deleteSession() {
  user = getEmptyUserObject()

  removeItemsFromLocalStorage(Object.keys(user), authLSPrefix)

  store.dispatch(setAllAuthState({
    isAuthenticated: false,
  }))
}

export function endSession() {
  if (!!window.MIXPANEL_PROJECT_TOKEN) {
    mixpanel.reset()
  }

  return new Promise(function(resolve, reject) {
    deleteSession()
    resolve()
  })
}
