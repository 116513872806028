import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { userIsAdmin, validateSession } from './../api/auth'

function PrivateRoute({ component: Component, requiresAdmin = false, ...rest }) {
    // There are instances when a user returns to our application after the
    // access token has expired, and instead of the token being refreshed, they
    // are often kicked out of the app altogether. We can resolve this issue by
    // initially setting the authentication flag to requiring an active refresh
    // token instead, and verifying the access token asynchronously.
    const isAuth = validateSession()

    if (!Component) {
        return null
    }

    if (requiresAdmin) {
        return (
            <Route {...rest} render={props => (
                userIsAdmin()
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )} />
        );
    }
    else {
        return (
            <Route {...rest} render={props => (
                isAuth
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )} />
        );
    }
}


export default PrivateRoute
