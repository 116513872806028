import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import mixpanel from 'mixpanel-browser'
import store from './app/store';

import AppContainer from './app/App.jsx'

import * as serviceWorker from './serviceWorker';

import './index.css';
import "react-selectize/dist/index.css";

if (!!window.MIXPANEL_PROJECT_TOKEN) {
  mixpanel.init(window.MIXPANEL_PROJECT_TOKEN)
}

render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('app')
)

window.Gisual = {
  "version": process.env.REACT_APP_VERSION,
  "environment_name": window.GISUAL_ENVIRONMENT,
  "api_url": window.GISUAL_API_URL,
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
