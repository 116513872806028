import React from "react";
import { Link, withRouter } from "react-router-dom";

import { validateSession } from "../../api/auth";
import { getGlobalUserId } from "../../api/aaa";

import styles from "./Header.module.css";

const HeaderComponent = () => {
  const isAuth = validateSession();
  const userid = getGlobalUserId();

  return (
    <header>
      <Link to="/" className={styles.logo}>
        Phronesis Terminal
      </Link>
      {isAuth && userid && (
        <Link className={styles.logoutBtn} to="/logout">
          Log out
        </Link>
      )}
    </header>
  );
};

export default withRouter(HeaderComponent);
